<template>
  <div>
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
      v-model="isOpen"
      :click-not-close="true"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $l.get("language-sidebar.header") }}</h4>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          @click="toggle"
        ></feather-icon>
      </div>

      <br />
      <br />

      <vs-sidebar-item @click="setLang('en-US')"
        ><img
          src="@/application/assets/images/graphics/EUA.png"
          style="width: 32px; margin-right: 15px"
        />
        English</vs-sidebar-item
      >
      <vs-sidebar-item @click="setLang('es-ES')"
        ><img
          src="@/application/assets/images/graphics/spain.png"
          style="width: 32px; margin-right: 15px"
        />
        Español</vs-sidebar-item
      >
      <vs-sidebar-item @click="setLang('pt-BR')"
        ><img
          src="@/application/assets/images/graphics/brazil.png"
          style="width: 32px; margin-right: 15px"
        />
        Português</vs-sidebar-item
      >
    </vs-sidebar>
    <div class="fixed-button animated heartBeat delay-1s">
      <vs-button
        icon="settings"
        color="primary"
        type="border"
        @click="toggle"
      ></vs-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "language-side-bar",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    setLang(lang) {
      this.$l.set(lang);
      this.$router.go();
    },
    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.fixed-button {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>
